/* App.css */
/* Сброс отступов и полей для всех элементов */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Центрирование содержимого */
  color: white;
  padding: 0; /* Уменьшите padding */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 40px; /* Убедитесь, что высота соответствует высоте nav */
}

.App-nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #20232a;
  padding: 10px 0;
  margin: 0;
}

.App-nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.App-nav li {
  margin: 0 15px;
}

.App-nav a {
  color: #61dafb;
  text-decoration: none;
  font-size: 18px;
}

.App-nav a:hover {
  text-decoration: underline;
}

.App-header-content {
  text-align: center;
  margin: 0;
}

.App {
  text-align: center;
  padding-top: 40px; /* Убедитесь, что это значение соответствует высоте хедера */
  margin: 0;
}

.App-main {
  padding: 0;
  margin: 0;
}

.App-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: #f4f4f4;
}

h2 {
  color: #20232a;
  font-size: 2em;
  margin: 0;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  margin: 0;
}

.section-home {
  height: 100vh;
  background: url('images/background_image.webp') no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  margin: 0;
}

.home-content {
  background: rgba(0, 0, 0, 0.6);
  padding: 40px;
  border-radius: 10px;
  max-width: 80%;
  margin: 0;
}

.home-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
  margin: 0;
}

.home-content .home-subtitle {
  font-size: 1.5em;
  margin-bottom: 30px;
  line-height: 1.5;
  color: #ffffff;
  margin: 0
}
