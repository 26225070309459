/* header.css */
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  height: 60px; /* Установите конкретную высоту */
  padding: 0 20px; /* Убедитесь, что padding по вертикали отсутствует */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.App-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #20232a;
  padding: 0; /* Убедитесь, что padding отсутствует */
  margin: 0;
}

.App-nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.App-nav li {
  margin: 0 15px;
}

.App-nav a {
  color: #61dafb;
  text-decoration: none;
  font-size: 18px;
}

.App-nav a:hover {
  text-decoration: underline;
}

.App-header-content {
  text-align: center;
  margin: 0;
}


