/* contact.css */
.contact-container {
    padding: 0;
    background: url('images/background_image_contact_section.webp') no-repeat center center;
    background-size: cover;
    text-align: left;
    color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    margin: 0;
  }
  
  .contact-content {
    background: rgba(255, 255, 255, 0.8);
    padding: 40px;
    border-radius: 10px;
    max-width: 50%;
    margin-right: 20px; /* Сдвиг стикера правее */
  }
  
  .contact-content h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .contact-content p {
    font-size: 1.2em;
    line-height: 1.6;
    cursor: pointer;
    user-select: none; /* Отключает выделение текста */
  }
  
  .contact-content p.copyable:hover {
    text-decoration: underline;
    color: #007bff; /* Измените цвет по вашему усмотрению */
  }
  