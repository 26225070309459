/* about.css */
.about-container {
    padding: 60px 0px;
    background: linear-gradient(to bottom, #f4f4f4, #e0e0e0, #f4f4f4); /* Плавный переход */
    text-align: center;
    margin: 0;
  }
  
  .about-container h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #20232a;
    margin: 0 0 40px 0;
  }
  
  .focus-areas {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0;
  }
  
  .focus-area {
    flex: 1;
    min-width: 250px;
    margin: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    transition: transform 0.3s, box-shadow 0.3s; /* Переходы для анимации */
  }
  
  .focus-area:hover {
    transform: translateY(-10px); /* Анимация при наведении */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .focus-area-manufacturers {
    background: url('images/background_image_focus1.webp') no-repeat center center;
    background-size: cover;
  }
  
  .focus-area-community {
    background: url('images/background_image_focus2.webp') no-repeat center center;
    background-size: cover;
  }
  
  .focus-content {
    padding: 20px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 10px;
  }
  
  .focus-content h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
    margin: 0;
  }
  
  .focus-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
    margin: 0;
  }
  
  .focus-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #61dafb;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .focus-button:hover {
    background-color: #21a1f1;
  }
  