/* home.css */
.section-home {
    height: 100vh;
    background: url('images/background_image.webp') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    margin: 0;
    margin-top: -60px; /* Подтянуть секцию вверх, значение должно соответствовать высоте хедера */
  }
  
  .home-content {
    background: rgba(0, 0, 0, 0.6);
    padding: 40px;
    border-radius: 10px;
    max-width: 80%;
    margin: 0;
  }
  
  .home-content h1 {
    font-size: 3em;
    margin-bottom: 20px;
    margin: 0;
  }
  
  .home-content .home-subtitle {
    font-size: 1.5em;
    margin-bottom: 30px;
    line-height: 1.5;
    color: #ffffff;
    margin: 0;
  }
  
  .home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2em;
    color: white;
    background-color: #61dafb;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .home-button:hover {
    background-color: #21a1f1;
  }
  
  
  