.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 10px 20px;
    border-top: 1px solid #000000; /* Черная линия для отделения от тела сайта */
    font-family: Arial, sans-serif; /* Выберите шрифт на свое усмотрение */
  }
  
  .footer-left {
    font-size: 14px;
    color: #000000;
  }
  
  .footer-right {
    font-size: 14px;
    color: #000000;
  }
  